// App.tsx

import React, { useEffect, useState } from 'react';
import Routes from 'routes/Routes';
import 'assets/scss/Saas.scss';
import config from '../src/config';
import { useLoadScript } from '@react-google-maps/api';
import { mapLibraries } from './pages/routing/fleet-view/complements/FleetUtils';
import { Spinner } from './components';
import Inactivity from 'pages/other/Inactivity';
import { useAuth0 } from '@auth0/auth0-react';
import { oAuthLogin } from 'redux/actions';
import useRedux from 'hooks/useRedux';
import { OAuthLogin } from 'helpers'; // Assuming this is your helper function
import { APICore } from 'helpers/api/apiCore';

const timeout = 43_200_000;
const promptBeforeIdle = 60_000;

const App = () => {
    const { dispatch } = useRedux();
    const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
    const [tokenProcessed, setTokenProcessed] = useState(false);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.GOOGLE_MAPS_API_KEY!,
        version: 'beta',
        //@ts-ignore
        libraries: mapLibraries,
    });

    useEffect(() => {
        const processToken = async () => {
            if (isAuthenticated && !tokenProcessed) {
                try {
                    console.log('Auth0 is authenticated, processing token...');
                    const _accessToken = await getAccessTokenSilently();

                    dispatch(oAuthLogin(user, _accessToken || ''));

                    setTokenProcessed(true);

                    // Only redirect if necessary
                    if (window.location.pathname !== '/home') {
                        window.location.href = '/home';
                    }
                } catch (error) {
                    console.error('Error processing token:', error);
                }
            }
        };

        processToken();
    }, [isAuthenticated, tokenProcessed, getAccessTokenSilently, getIdTokenClaims, user, dispatch]);

    // Log for checking the status of Auth0 and Google Maps loading
    if (isLoading) {
        console.log('Auth0 is loading...');
    }

    if (!isLoaded) {
        console.log('Google Maps API is loading...');
    }

    // Check the loading states and display spinner if necessary
    if (isLoading || !isLoaded) {
        return <Spinner className="middle_center_spinner" />;
    }

    // Log when the application is fully loaded
    console.log('App is fully loaded');

    return (
        <>
            <Routes />
            <Inactivity timeout={timeout} promptBeforeIdle={promptBeforeIdle} />
        </>
    );
};

export default App;
