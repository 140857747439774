// import { useOktaAuth } from '@okta/okta-react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const Root = () => {
    const { isAuthenticated } = useAuth0();

    return <Navigate to={`/${isAuthenticated ? 'home' : 'account/login'}`} />;
};

export default Root;
