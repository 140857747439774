export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    roles?: string[];
    children?: MenuItemType[];
};

const OPERATIONS_ROLE = 'Operations';
const ROUTING_ROLE = 'Routing';
const SALES_ROLE = 'Sales';
const MANAGER_ROLE = 'Manager';
const CUSTOMER_SERVICE_ROLE = 'CustomerService';

const MENU_ITEMS: MenuItemType[] = [
    // { key: 'navigation', label: 'Navigation', isTitle: true },
    // {
    //     key: 'dashboards',
    //     label: 'Roles Management',
    //     isTitle: false,
    //     icon: 'uil-users-alt',
    //     // badge: { variant: 'success', text: '4' },
    //     children: [
    //         {
    //             key: 'ds-analytics',
    //             label: 'Permissions',
    //             url: '/roles-management/permissions',
    //             parentKey: 'dashboards',
    //         },
    //         {
    //             key: 'ds-ecommerce',
    //             label: 'Groups',
    //             url: '/roles-management/groups',
    //             parentKey: 'dashboards',
    //         },
    //         // {
    //         //     key: 'ds-project',
    //         //     label: 'Projects',
    //         //     url: '/dashboard/project',
    //         //     parentKey: 'dashboards',
    //         // },
    //         // {
    //         //     key: 'ds-ewallet',
    //         //     label: 'E-Wallet',
    //         //     url: '/dashboard/e-wallet',
    //         //     parentKey: 'dashboards',
    //         //     badge: { variant: 'danger', text: 'New' },
    //         // },
    //     ],
    // },
    // { key: 'apps', label: 'Apps', isTitle: true },
    // {
    //     key: 'apps-calendar',
    //     label: 'Calendar',
    //     isTitle: false,
    //     icon: 'uil-calender',
    //     url: '/apps/calendar',
    // },
    // {
    //     key: 'apps-chat',
    //     label: 'Chat',
    //     isTitle: false,
    //     icon: 'uil-comments-alt',
    //     url: '/apps/chat',
    // },
    // {
    //     key: 'apps-crm',
    //     label: 'CRM',
    //     isTitle: false,
    //     badge: { variant: 'danger', text: 'New' },
    //     icon: 'uil-tachometer-fast',
    //     children: [
    //         {
    //             key: 'crm-products',
    //             label: 'Dashboard',
    //             url: '/apps/crm/dashboard',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-projects',
    //             label: 'Project',
    //             url: '/apps/crm/projects',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-orders',
    //             label: 'Orders List',
    //             url: '/apps/crm/orders',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-clients',
    //             label: 'Clients',
    //             url: '/apps/crm/clients',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-management',
    //             label: 'Management',
    //             url: '/apps/crm/management',
    //             parentKey: 'apps-crm',
    //         },
    //     ],
    // },
    {
        key: 'home',
        label: 'Home',
        isTitle: false,
        icon: 'mdi mdi-home fs-3',
        url: '/home',
        roles: [OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE],
    },

    {
        key: 'routing',
        label: 'Routing',
        isTitle: false,
        icon: 'mdi mdi-directions-fork fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'routing-tasks',
                label: 'Routing tasks',
                url: '/routing/routing-tasks',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'better-trucks-routing-tasks',
                label: 'Routing tasks with ELS',
                url: '/better-trucks/routing-tasks',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            // {
            //     key: 'routing-solutions',
            //     label: 'Routing Solutions',
            //     url: '/routing/routing-solutions',
            //     parentKey: 'routing',
            //     roles: [MANAGER_ROLE],
            // },
            // {
            //     key: 'fleet-view',
            //     label: 'Fleet View',
            //     // icon: 'uil-check-square',
            //     url: '/routing/fleet-view',
            //     parentKey: 'routing',
            // },
            // {
            //     key: 'routing-solutions-v2',
            //     label: 'Routing Solutions V2',
            //     // icon: 'uil-check-square',
            //     url: '/routing/routing-solutions-v2',
            //     parentKey: 'routing',
            //     roles: [MANAGER_ROLE],
            // },
            {
                key: '3rd-party-routing',
                label: '3rd Party Routing',
                // icon: 'uil-check-square',
                url: '/routing/3rd-party-routing',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'pickups',
                label: 'Pickups',
                url: '/routing/pickups',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE],
            },
        ],
    },
    {
        key: 'dashboards',
        label: 'Statistics',
        isTitle: false,
        icon: 'mdi mdi-monitor-dashboard fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'warehouse',
                label: 'Warehouse Metrics',
                url: '/dashboards/warehouse-metrics',
                parentKey: 'dashboards',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    {
        key: 'better-trucks',
        label: 'Better Trucks',
        isTitle: false,
        icon: 'mdi mdi-truck fs-3',
        roles: [MANAGER_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'companies',
                label: 'Companies',
                url: '/better-trucks/companies',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'cities',
                label: 'Cities',
                url: '/better-trucks/cities',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'Ops hubs',
                label: 'BT Hubs',
                url: '/better-trucks/ops-hubs',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'company-hub',
                label: 'Customer Hubs',
                url: '/better-trucks/company-hubs',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'users',
                label: 'Users',
                url: '/better-trucks/users',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'zip-codes',
                label: 'Zip codes',
                url: '/better-trucks/zip-codes',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            // {
            //     key: 'zip-code-management',
            //     label: 'Zip codes exceptions',
            //     url: '/better-trucks/zip-code-exceptions',
            //     parentKey: 'better-trucks',
            // },
            {
                key: 'zip-code-management-new',
                label: 'Modify zip codes',
                url: '/better-trucks/modify-zip-code',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'vehicle-templates',
                label: 'Vehicle Templates',
                url: '/better-trucks/vehicle-templates',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'templates',
                label: 'Templates',
                url: '/better-trucks/templates',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
        ],
    },
    {
        key: 'apps-tasks',
        label: 'Rates Management',
        isTitle: false,
        icon: 'mdi mdi-airplane-takeoff fs-3',
        roles: [MANAGER_ROLE, SALES_ROLE],
        children: [
            {
                key: 'task-list',
                label: 'Basic rates',
                url: '/rates-management/basic-rates',
                parentKey: 'apps-tasks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            // {
            //     key: 'task-details',
            //     label: 'Details',
            //     url: '/apps/tasks/details',
            //     parentKey: 'apps-tasks',
            // },
            // {
            //     key: 'task-kanban',
            //     label: 'Kanban Board',
            //     url: '/apps/tasks/kanban',
            //     parentKey: 'apps-tasks',
            // },
        ],
    },
    {
        key: 'dynamic-pricing',
        label: 'Dynamic Pricing',
        isTitle: false,
        icon: 'dripicons-bookmarks fs-3',
        roles: [MANAGER_ROLE, SALES_ROLE],
        children: [
            // {
            //     key: 'all-pricing-rules',
            //     label: 'All Pricing Rules',
            //     url: '/dynamic-pricing/all-pricing-rules',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            {
                key: 'all-company-pricing-rules',
                label: 'Assigned Pricing Rules',
                url: '/dynamic-pricing/all-company-pricing-rules',
                parentKey: 'dynamic-pricing',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            // {
            //     key: 'create-pricing-rule',
            //     label: 'Pricing Rule',
            //     url: '/dynamic-pricing/pricing-rule',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            // {
            //     key: 'active-pricing-rule',
            //     label: 'Active Pricing Rules',
            //     url: '/dynamic-pricing/active-pricing-rules',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            // {
            //     key: 'net-base-rate',
            //     label: 'Net Base Rate',
            //     url: '/dynamic-pricing/net-base-rate',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
        ],
    },
    {
        key: 'orphan-labels',
        label: 'Labels',
        isTitle: false,
        icon: 'mdi mdi-database-search-outline fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            // {
            //     key: 'orphan-labels-manifest',
            //     label: 'Add single package',
            //     url: '/add-package',
            //     parentKey: 'orphan-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            // },
            // {
            //     key: 'orphan-labels-api-customer',
            //     label: 'Verify labels',
            //     url: '/verify-label',
            //     parentKey: 'orphan-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            // },
            {
                key: 'orphans-labels-api-customer',
                label: 'Verify label / Add package',
                url: '/verify-label-add-package',
                parentKey: 'orphan-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    {
        key: 'contaier-labels',
        label: 'Containers',
        isTitle: false,
        icon: 'dripicons-article fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
        children: [
            // {
            //     key: 'track container',
            //     label: 'Track containers',
            //     url: '/track-containers',
            //     parentKey: 'contaier-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            // },
            {
                key: 'track-containers',
                label: 'Track Containers',
                url: '/labels',
                parentKey: 'contaier-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'metro-containers',
                label: 'Metro containers',
                url: '/container-label',
                parentKey: 'contaier-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Company Containers',
                label: 'Company containers',
                url: '/company-container-label',
                parentKey: 'contaier-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'mtulti-metro-config',
                label: 'Multi-metro config',
                url: '/container-hub-config',
                parentKey: 'contaier-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Line haul config',
                label: 'Line haul config',
                url: '/line-haul-config',
                parentKey: 'contaier-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
        ],
    },
    // {
    //     key: 'apps-file-manager',
    //     label: 'File Manager',
    //     isTitle: false,
    //     icon: 'uil-folder-plus',
    //     url: '/apps/file',
    // },

    // { key: 'custom', label: 'Custom', isTitle: true },
    {
        key: 'customer-service',
        label: 'Customer services',
        isTitle: false,
        icon: 'uil-copy-alt fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            // {
            //     key: 'page-profile',
            //     label: 'Track shipment',
            //     url: '/customer-service/track-shipment',
            //     parentKey: 'customer-service',
            // },
            {
                key: 'podReview',
                label: 'POD Review',
                url: '/customer-service/pod-review',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'track-shipments',
                label: 'Track shipments',
                url: '/customer-service/track-shipments',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'claims',
                label: 'Claims',
                url: '/customer-service/claims',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'page-company-service-levels',
                label: 'Service level exceptions',
                url: '/customer-service/service-levels-exceptions',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'page-service-levels',
                label: 'Service levels',
                url: '/customer-service/service-levels',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'event-types',
                label: 'Event types',
                url: '/customer-service/event-types',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'address-corrections-notifications',
                label: 'Address Corrections Notifications',
                url: '/customer-service/address-corrections-notifications',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE],
            },
        ],
    },
    {
        key: 'fleet-ops',
        label: 'Fleet Ops',
        isTitle: false,
        icon: 'dripicons-web fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'fleet-view',
                label: 'Fleet View',
                // icon: 'uil-check-square',
                url: '/fleet-ops/fleet-view',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'zip-based-routing',
                label: 'Zip based routing',
                url: '/fleet-ops/zip-based-routing',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'driver-assignment',
                label: 'Driver assignment',
                url: '/fleet-ops/driver-assignment',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'package-inquiry',
                label: 'Package inquiry',
                url: '/fleet-ops/package-inquiry',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    {
        key: 'third-party-carriers',
        label: '3rd Party Carriers',
        isTitle: false,
        icon: 'dripicons-direction fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'carriers',
                label: 'Carriers',
                url: '/third-party-carriers/carriers',
                parentKey: 'third-party-carriers',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'carrier-zips',
                label: 'Zips',
                url: '/third-party-carriers/carrier-zips',
                parentKey: 'third-party-carriers',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    // {
    //     key: 'landing',
    //     label: 'Landing',
    //     isTitle: false,
    //     icon: 'uil-globe',
    //     url: '/landing',
    //     target: '_blank',
    //     badge: { variant: 'secondary', text: 'New' },
    // },

    // { key: 'components', label: 'Components', isTitle: true },
    // {
    //     key: 'base-ui',
    //     label: 'Base UI',
    //     isTitle: false,
    //     icon: 'uil-box',
    //     children: [
    //         {
    //             key: 'base-ui-accordions',
    //             label: 'Accordions',
    //             url: '/ui/base-ui/accordions',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-alerts',
    //             label: 'Alerts',
    //             url: '/ui/base-ui/alerts',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-avatars',
    //             label: 'Avatars',
    //             url: '/ui/base-ui/avatars',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-badges',
    //             label: 'Badges',
    //             url: '/ui/base-ui/badges',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-breadcrumb',
    //             label: 'Breadcrumb',
    //             url: '/ui/base-ui/breadcrumb',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-buttons',
    //             label: 'Buttons',
    //             url: '/ui/base-ui/buttons',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-cards',
    //             label: 'Cards',
    //             url: '/ui/base-ui/cards',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-carousel',
    //             label: 'Carousel',
    //             url: '/ui/base-ui/carousel',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-dropdown',
    //             label: 'Dropdowns',
    //             url: '/ui/base-ui/dropdowns',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-embedvideo',
    //             label: 'Embed Video',
    //             url: '/ui/base-ui/embedvideo',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-grid',
    //             label: 'Grid',
    //             url: '/ui/base-ui/grid',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-listgroups',
    //             label: 'List Groups',
    //             url: '/ui/base-ui/listgroups',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-modals',
    //             label: 'Modals',
    //             url: '/ui/base-ui/modals',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-notifications',
    //             label: 'Notifications',
    //             url: '/ui/base-ui/notifications',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-offcanvas',
    //             label: 'Offcanvas',
    //             url: '/ui/base-ui/offcanvas',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-placeholders',
    //             label: 'Placeholders',
    //             url: '/ui/base-ui/placeholders',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-paginations',
    //             label: 'Paginations',
    //             url: '/ui/base-ui/paginations',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-popovers',
    //             label: 'Popovers',
    //             url: '/ui/base-ui/popovers',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-progress',
    //             label: 'Progress',
    //             url: '/ui/base-ui/progress',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-ribbons',
    //             label: 'Ribbons',
    //             url: '/ui/base-ui/ribbons',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-spinners',
    //             label: 'Spinners',
    //             url: '/ui/base-ui/spinners',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-tabs',
    //             label: 'Tabs',
    //             url: '/ui/base-ui/tabs',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-tooltips',
    //             label: 'Tooltips',
    //             url: '/ui/base-ui/tooltips',
    //             parentKey: 'base-ui',
    //         },
    //         {
    //             key: 'base-ui-typography',
    //             label: 'Typography',
    //             url: '/ui/base-ui/typography',
    //             parentKey: 'base-ui',
    //         },
    //     ],
    // },
    // {
    //     key: 'extended-ui',
    //     label: 'Extended UI',
    //     isTitle: false,
    //     icon: 'uil-package',
    //     children: [
    //         {
    //             key: 'extended-ui-dragdrop',
    //             label: 'Drag and Drop',
    //             url: '/ui/extended/dragdrop',
    //             parentKey: 'extended-ui',
    //         },
    //         {
    //             key: 'extended-ui-rangesliders',
    //             label: 'Range Sliders',
    //             url: '/ui/extended/rangesliders',
    //             parentKey: 'extended-ui',
    //         },
    //         {
    //             key: 'extended-ui-ratings',
    //             label: 'Ratings',
    //             url: '/ui/extended/ratings',
    //             parentKey: 'extended-ui',
    //         },
    //     ],
    // },
    // {
    //     key: 'widgets',
    //     label: 'Widgets',
    //     isTitle: false,
    //     icon: 'uil-layer-group',
    //     url: '/ui/widgets',
    // },
    // {
    //     key: 'icons',
    //     label: 'Icons',
    //     isTitle: false,
    //     icon: 'uil-streering',
    //     children: [
    //         {
    //             key: 'icon-dripicons',
    //             label: 'Dripicons',
    //             url: '/ui/icons/dripicons',
    //             parentKey: 'icons',
    //         },
    //         {
    //             key: 'icon-mdiicons',
    //             label: 'Material Design',
    //             url: '/ui/icons/mdi',
    //             parentKey: 'icons',
    //         },
    //         {
    //             key: 'icon-unicons',
    //             label: 'Unicons',
    //             url: '/ui/icons/unicons',
    //             parentKey: 'icons',
    //         },
    //     ],
    // },
    // {
    //     key: 'forms',
    //     label: 'Forms',
    //     isTitle: false,
    //     icon: 'uil-document-layout-center',
    //     children: [
    //         {
    //             key: 'form-basic',
    //             label: 'Basic Elements',
    //             url: '/ui/forms/basic',
    //             parentKey: 'forms',
    //         },
    //         {
    //             key: 'form-advanced',
    //             label: 'Form Advanced',
    //             url: '/ui/forms/advanced',
    //             parentKey: 'forms',
    //         },
    //         {
    //             key: 'form-validation',
    //             label: 'Validation',
    //             url: '/ui/forms/validation',
    //             parentKey: 'forms',
    //         },
    //         {
    //             key: 'form-wizard',
    //             label: 'Wizard',
    //             url: '/ui/forms/wizard',
    //             parentKey: 'forms',
    //         },
    //         {
    //             key: 'form-upload',
    //             label: 'File Upload',
    //             url: '/ui/forms/upload',
    //             parentKey: 'forms',
    //         },
    //         {
    //             key: 'form-editors',
    //             label: 'Editors',
    //             url: '/ui/forms/editors',
    //             parentKey: 'forms',
    //         },
    //     ],
    // },
    // {
    //     key: 'charts',
    //     label: 'Charts',
    //     isTitle: false,
    //     icon: 'uil-chart',
    //     children: [
    //         {
    //             key: 'chart-apex',
    //             label: 'Apex Charts',
    //             url: '/ui/charts/apex',
    //             parentKey: 'charts',
    //         },
    //         {
    //             key: 'chart-chartjs',
    //             label: 'Chartjs',
    //             url: '/ui/charts/chartjs',
    //             parentKey: 'charts',
    //         },
    //     ],
    // },
    // {
    //     key: 'tables',
    //     label: 'Tables',
    //     isTitle: false,
    //     icon: 'uil-table',
    //     children: [
    //         {
    //             key: 'table-basic',
    //             label: 'Basic Tables',
    //             url: '/ui/tables/basic',
    //             parentKey: 'tables',
    //         },
    //         {
    //             key: 'table-advanced',
    //             label: 'Advanced Tables',
    //             url: '/ui/tables/advanced',
    //             parentKey: 'tables',
    //         },
    //     ],
    // },
    // {
    //     key: 'maps',
    //     label: 'Maps',
    //     isTitle: false,
    //     icon: 'uil-location-point',
    //     children: [
    //         {
    //             key: 'maps-googlemaps',
    //             label: 'Google Maps',
    //             url: '/ui/maps/googlemaps',
    //             parentKey: 'maps',
    //         },
    //         {
    //             key: 'maps-vectormaps',
    //             label: 'Vector Maps',
    //             url: '/ui/maps/vectormaps',
    //             parentKey: 'maps',
    //         },
    //     ],
    // },
    // {
    //     key: 'menu-levels',
    //     label: 'Menu Levels',
    //     isTitle: false,
    //     icon: 'uil-folder-plus',
    //     children: [
    //         {
    //             key: 'menu-levels-1-1',
    //             label: 'Level 1.1',
    //             url: '/',
    //             parentKey: 'menu-levels',
    //             children: [
    //                 {
    //                     key: 'menu-levels-2-1',
    //                     label: 'Level 2.1',
    //                     url: '/',
    //                     parentKey: 'menu-levels-1-1',
    //                     children: [
    //                         {
    //                             key: 'menu-levels-3-1',
    //                             label: 'Level 3.1',
    //                             url: '/',
    //                             parentKey: 'menu-levels-2-1',
    //                         },
    //                         {
    //                             key: 'menu-levels-3-2',
    //                             label: 'Level 3.2',
    //                             url: '/',
    //                             parentKey: 'menu-levels-2-1',
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     key: 'menu-levels-2-2',
    //                     label: 'Level 2.2',
    //                     url: '/',
    //                     parentKey: 'menu-levels-1-1',
    //                 },
    //             ],
    //         },
    //         {
    //             key: 'menu-levels-1-2',
    //             label: 'Level 1.2',
    //             url: '/',
    //             parentKey: 'menu-levels',
    //         },
    //     ],
    // },
];

export { MENU_ITEMS, OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE };
