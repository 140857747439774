import React, { useRef, useEffect, forwardRef, useState, memo, ComponentType } from 'react';
import {
    useTable,
    useSortBy,
    usePagination,
    useRowSelect,
    useGlobalFilter,
    useAsyncDebounce,
    useExpanded,
    Column,
    Row,
    FilterValue,
    SortingRule,
} from 'react-table';
import {ReactComponent as PolygonRight} from '../../assets/images/polygon-right.svg';
import {ReactComponent as PolygonDown} from '../../assets/images/polygon-down.svg';
import classNames from 'classnames';
import { Pagination, PageSize } from './Pagination';
import { Col, Row as BootstrapRow } from 'react-bootstrap';

export type CellFormatter<T extends Object = {}> = {
    row: Row<T>;
};

export type GlobalFilterProps = {
    preGlobalFilteredRows: any;
    globalFilter: any;
    setGlobalFilter: (filterValue: FilterValue) => void;
    searchBoxClass?: string;
    customTitle?: any;
    customClassName?: string;
    shouldHaveClearAction?: boolean;
    customWrapperClass?: string;
    placeholder?: string;
    showWorldIcon?: boolean;
    tooltipInfo?: string;
};

export const defaultGlobalFilterValues: GlobalFilterProps = {
    preGlobalFilteredRows: [],
    globalFilter: undefined,
    searchBoxClass: '',
    setGlobalFilter: (value: any) => {},
};

const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    searchBoxClass,
    customTitle,
    customClassName,
    customWrapperClass,
    placeholder,
    shouldHaveClearAction = false,
}: GlobalFilterProps) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState<any>(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className={classNames(searchBoxClass)}>
            <span className={`d-flex align-items-center input-group ${customWrapperClass}`}>
                {customTitle ?? 'Search:'}
                <input
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={!placeholder ? `Search ${count} records...` : placeholder}
                    className={customClassName ?? 'form-control w-auto ms-1'}
                    type="text"
                    aria-describedby="search-addon"
                />
                {shouldHaveClearAction && (
                    <div className="input-group-append">
                        <button
                            className="btn btn-light"
                            type="button"
                            onClick={() => {
                                setValue('');
                                onChange('');
                            }}>
                            <i
                                aria-hidden="true"
                                className="mdi mdi-delete"
                                title="Clear"
                                style={{ fontSize: '14px' }}></i>
                        </button>
                    </div>
                )}
            </span>
        </div>
    );
};

type IndeterminateCheckboxProps = {
    indeterminate: any;
    children?: React.ReactNode;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef: any = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div className="form-check">
                <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
            </div>
        );
    }
);

type TableProps<TableValues> = {
    isSearchable?: boolean;
    isSortable?: boolean;
    pagination?: boolean;
    isSelectable?: boolean;
    isExpandable?: boolean;
    sizePerPageList?: PageSize[];
    columns: ReadonlyArray<Column>;
    data: TableValues[];
    pageSize?: number;
    searchBoxClass?: string;
    tableClass?: string;
    theadClass?: string;
    Actions?: JSX.Element;
    paginationPosition?: 'top' | 'bottom' | 'both';
    setSelectedRowIds?: (selectedRecords: number[]) => void;
    isToggleExpandedRows?: boolean;

    // Server paged table props only

    pageCount?: number;
    pageIndex?: number;
    totalCount?: number;
    manualPagination?: boolean;
    manualSortBy?: boolean;
    autoResetSortBy?: boolean;
    autoResetGlobalFilter?: boolean;
    onChangePage?: (value: string) => void;
    onChangePageSize?: (value: string) => void;
    toggleSortBy?: (value: SortingRule<{}>[]) => void;
    // content row actions

    onWholeRowClick?: (value?: string) => void;
    onWholeRowClickStyle?: (value?: string) => void | object;
    renderRowSubComponent?: (value?: any) => any;
    isExternallyExpandable?: boolean;
    customHeadClass?: string;
};

// const Table = <TableValues extends object = {}>(props: TableProps<TableValues>) => {
const Table = <T extends object = {}>(props: TableProps<T>) => {
    const isSearchable = props['isSearchable'] || false;
    const isSortable = props['isSortable'] || false;
    const pagination = props['pagination'] || false;
    const isSelectable = props['isSelectable'] || false;
    const isExpandable = props['isExpandable'] || false;
    const isExternallyExpandable = props['isExternallyExpandable'] || false;
    const sizePerPageList = props['sizePerPageList'] || [];
    const renderRowSubComponent = props['renderRowSubComponent'] || undefined;
    const paginationPosition = pagination ? props.paginationPosition || 'bottom' : null;

    const onWholeRowClick = props['onWholeRowClick'] || undefined;
    const onWholeRowClickStyle = props['onWholeRowClickStyle'] || undefined;
    const isToggleExpandedRows = props['isToggleExpandedRows'] || false;

    let otherProps: any = {};

    if (isSearchable) {
        otherProps['useGlobalFilter'] = useGlobalFilter;
    }
    if (isSortable) {
        otherProps['useSortBy'] = useSortBy;
    }
    if (isExpandable) {
        otherProps['useExpanded'] = useExpanded;
    }
    if (pagination) {
        otherProps['usePagination'] = usePagination;
    }
    if (isSelectable) {
        otherProps['useRowSelect'] = useRowSelect;
    }

    const dataTable = useTable(
        {
            columns: props['columns'],
            data: props['data'],
            initialState: {
                pageSize: props['pageSize'] ?? 10,
            },
            manualPagination: props.manualPagination,
            manualSortBy: props.manualSortBy,
            autoResetSortBy: props.autoResetSortBy,
            autoResetGlobalFilter: props.autoResetGlobalFilter,
            manualExpanding: false,
            autoResetExpanded: false,
            toggleRowExpanded: (e: any) => {
                console.log('toggleRowExpanded');
            },
        },

        otherProps.hasOwnProperty('useGlobalFilter') && otherProps['useGlobalFilter'],
        otherProps.hasOwnProperty('useSortBy') && otherProps['useSortBy'],
        otherProps.hasOwnProperty('useExpanded') && otherProps['useExpanded'],
        otherProps.hasOwnProperty('usePagination') && otherProps['usePagination'],
        otherProps.hasOwnProperty('useRowSelect') && otherProps['useRowSelect'],

        (hooks) => {
            isSelectable &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        width: '0px',
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }: any) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                    },
                    ...columns,
                ]);

            isExpandable &&
                !isExternallyExpandable &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        // Build our expander column
                        id: 'expander', // Make sure it has an ID
                        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                            <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? (isToggleExpandedRows ? '': '-') : (isToggleExpandedRows ? '': '+')}</span>
                        ),
                        Cell: ({ row }) =>
                            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                            // to build the toggle for expanding a row
                           (!isToggleExpandedRows ? row.canExpand : isExpandable) ? (
                                <span
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}
                                    
                                    onClick={() => {
                                        if(isToggleExpandedRows){
                                            if (row.isExpanded) {
                                                dataTable.toggleRowExpanded([row.id], false);
                                            } else {
                                                dataTable.toggleAllRowsExpanded(false);
                                                dataTable.toggleRowExpanded([row.id], true);
                                            }
                                        }else
                                        {
                                            dataTable.toggleRowExpanded([row.id]);
                                        }
                                    }}>
                                    {row.isExpanded ? (isToggleExpandedRows ? <PolygonDown/>: '-') : (isToggleExpandedRows ? <PolygonRight/>: '+')}
                                </span>
                            ) : null,
                    },
                    ...columns,
                ]);
        }
    );

    if (props.pageCount !== undefined) {
        dataTable.pageCount = props.pageCount;
        dataTable.pageOptions.length = props.pageCount;
        dataTable.totalCount = props.totalCount;
    }
    if (props.pageIndex !== undefined) {
        dataTable.state.pageIndex = props.pageIndex;
    }

    useEffect(() => {
        if (isToggleExpandedRows) {
          dataTable.toggleAllRowsExpanded(false);
        }
      }, [dataTable.state.pageIndex, dataTable.state.pageSize]);
      
      
    useEffect(() => {
        props.setSelectedRowIds &&
            props.setSelectedRowIds(Object.keys(dataTable.state.selectedRowIds).map((key) => parseInt(key)));
    }, [dataTable.state.selectedRowIds]);

    useEffect(() => {
        props.toggleSortBy && props.toggleSortBy(dataTable.state.sortBy);
    }, [dataTable.state.sortBy]);

    let rows = pagination ? dataTable.page : dataTable.rows;

    const paginationElement = pagination && (
        <>
            {(paginationPosition === 'bottom' || paginationPosition === 'both') && <hr />}
            <Pagination
                tableProps={dataTable}
                sizePerPageList={sizePerPageList}
                onChangePage={props.onChangePage}
                onChangePageSize={props.onChangePageSize}
            />
            {(paginationPosition === 'top' || paginationPosition === 'both') && <hr />}
        </>
    );

    /* To handle a whole row click */
    const handleRowClick = (e: any) => {
        // console.log({ e });
        onWholeRowClick && onWholeRowClick(e);
    };

    /* To handle a whole row click style to highlight background on click */
    const handleExternalCustomStyle = (e: any): any => {
        if (typeof onWholeRowClickStyle === 'function') {
            return onWholeRowClickStyle && onWholeRowClickStyle(e);
        }

        return onWholeRowClickStyle || {};
    };

    return (
        <>
            {isSearchable && (
                <>
                    <BootstrapRow style={{ marginBottom: '10px' }}>
                        <Col xs={12}>
                            <BootstrapRow>
                                <Col xs={5}>
                                    <GlobalFilter
                                        preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
                                        globalFilter={dataTable.state.globalFilter}
                                        setGlobalFilter={dataTable.setGlobalFilter}
                                        searchBoxClass={props['searchBoxClass']}
                                    />
                                </Col>
                                <Col style={{ display: 'flex', justifyContent: 'right' }} mt={2} xs={7}>
                                    {props.Actions && props.Actions}
                                </Col>
                            </BootstrapRow>
                        </Col>
                    </BootstrapRow>
                    <hr className="my-1" />
                </>
            )}

            {pagination && (paginationPosition === 'top' || paginationPosition === 'both') && paginationElement}
            <div className="table-responsive">
                <table
                    {...dataTable.getTableProps()}
                    className={classNames(
                        !props['customHeadClass'] && 'table table-centered react-table table-font-colors',
                        !props['customHeadClass'] && props['tableClass'],
                        props['customHeadClass'] && props['customHeadClass']
                    )}>
                    <thead
                        className={`${(!props['customHeadClass'] && props['theadClass']) ?? props['customHeadClass']}`}>
                        {dataTable.headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.defaultCanSort && column.getSortByToggleProps()
                                        )}
                                        className={classNames(
                                            {
                                                sorting_desc: column.isSortedDesc === true,
                                                sorting_asc: column.isSortedDesc === false,
                                                sortable: column.defaultCanSort === true,
                                            },
                                            'table-th',
                                            column?.customStyle
                                        )}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {!!dataTable.rows.length && (
                        <tbody {...dataTable.getTableBodyProps()}>
                            {(rows || []).map((row, i) => {
                                dataTable.prepareRow(row);
                                const rowProps = row.getRowProps();

                                return (
                                    <>
                                        <tr
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                handleRowClick(row.original);
                                            }}
                                            style={handleExternalCustomStyle(row.original)}
                                            // @ts-ignore
                                            aria-label={row?.original?.ariaLabel || undefined}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.column.minWidth,
                                                                width: cell.column.width,
                                                            },
                                                        })}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {(row.isExpanded && !!renderRowSubComponent && (
                                            <tr>
                                                <td colSpan={row.cells.length}>
                                                    <span>
                                                        {renderRowSubComponent({
                                                            row,
                                                            counter: row.cells.length,
                                                            rowProps,
                                                        })}
                                                    </span>
                                                </td>
                                            </tr>
                                        )) ||
                                            null}
                                    </>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
            {!dataTable.rows.length && <NoDataFound />}
            {pagination && (paginationPosition === 'bottom' || paginationPosition === 'both') && paginationElement}
        </>
    );
};

const NoDataFound = () => {
    return (
        <BootstrapRow>
            <Col xs={12} className="d-flex justify-content-center">
                No data found!
            </Col>
        </BootstrapRow>
    );
};

export { Table, GlobalFilter };

const withMemo = <T extends object>(Component: ComponentType<TableProps<T>>) => {
    const MemoizedComponent = memo(Component) as ComponentType<TableProps<T>>;
    return (props: TableProps<T>) => <MemoizedComponent {...props} />;
};

export const MemoizedTable = withMemo(Table);
