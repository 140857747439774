import { BTAction } from 'redux/constants';
import { AuthActionTypes } from './constants';

type User = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

export const setAuthorization = (token: string | null): BTAction<any> => ({
    type: AuthActionTypes.SET_USER_AUTHENTICATION,
    payload: { authorization: { token } },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): BTAction<any> => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): BTAction<any> => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const oAuthLogin = (user: any, token: string): BTAction<any> => ({
    type: AuthActionTypes.OAUTH_LOGIN_USER,
    payload: { user, token },
});

export const setIsAuthed = (val: boolean): BTAction<any> => ({
    type: AuthActionTypes.UPDATE_IS_AUTHED,
    payload: {
        isAuthed: val,
    },
});

export const setIsActive = (val: boolean): BTAction<any> => ({
    type: AuthActionTypes.UPDATE_IS_ACTIVE,
    payload: {
        isActive: val,
    },
});
