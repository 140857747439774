import ReactDOM from 'react-dom/client';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import config from 'config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Auth0Provider
                domain={config.OKTA_ISSUER || ''}
                clientId={config.OKTA_CLIENT_ID || ''}
                authorizationParams={{
                    redirect_uri: `${window.location.origin}/home`,
                    audience: config.OKTA_AUDIENCE, // Auth0 Management API
                    scope: 'openid profile email',
                    response_mode: 'query', // or 'fragment' depending on your needs
                }}>
                <App />
            </Auth0Provider>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
