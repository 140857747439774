import { SagaIterator } from '@redux-saga/core';
import LocalStorage from 'helpers/api/LocalStorage';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { authApiResponseError, authApiResponseSuccess, setAuthorization, setIsAuthed } from './actions';
import { AuthActionTypes } from './constants';

/**
 * Login the user
 * @param {*} payload - idToken
 */
function* OAuth({ payload: { user, token }, type }: any): SagaIterator {
    try {
        const firstName = user['given_name'];
        const lastName = user['family_name'];
        const picture = user['picture'];

        const roles = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        const groups = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/groups'];

        const finalUser = {
            ...user,
            token: token,
            id: user['sub'],
            imageUrl: picture,
            firstName,
            lastName,
            username: user['nickname'],
            groups: groups,
            roles: roles,
        };
        LocalStorage.setLoggedInUser(finalUser);

        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, finalUser));
        yield put(setIsAuthed(true));
    } catch (error: any) {
        // console.log({ error });
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'Something went wrong while logging in!'));
        LocalStorage.setLoggedInUser(null);
        yield put(setIsAuthed(true));
    }
}

export function* watchOAuthUser() {
    yield takeEvery(AuthActionTypes.OAUTH_LOGIN_USER, OAuth);
}
function* authSaga() {
    yield all([fork(watchOAuthUser)]);
}

export default authSaga;
